<template>
  <div>
    <div class="breadCurmb mt-3">
      <label>
        <router-link to="/">{{ $t("Home") }}</router-link>
      </label>
      <span>/</span>
      <label active>{{ $t("SystemConstant") }}</label>
      <span>/</span>
      <label active>{{ $t("Divisions") }}</label>
    </div>

    <!-- Start Dialog Delete -->
    <v-dialog v-model="dialogDelete" :width="config.deleteModalWidth">
      <v-card>
        <v-toolbar color="third" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            {{ $t("Confirm") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="py-0 pa-4 pt-4 black--text">
          <h3 class="clr-primary">
            {{ $t("AreYouSureYouWantToDelete") }}
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            small
            class="body-2 font-weight-bold"
            @click.native="dialogDelete = false"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="success"
            class="body-2 font-weight-bold"
            outlined
            small
            @click.native="deleteItemConfirm"
          >
            {{ $t("Ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Dialog Delete -->

    <v-toolbar flat>
      <v-toolbar-title>{{ $t(config.tableName) }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-dialog
        v-model="dialog"
        :width="config.modalFormWidth"
        @click:outside="closeModal"
      >
        <template v-slot:activator="{ on, attrs }">
          <div>
            <v-btn
              v-if="
                user &&
                user.permissions &&
                user.permissions.includes('store_division')
              "
              color="info"
              @click="clearData"
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              small
            >
              <span class="addNewLabel">{{ $t("AddNew") }}</span>

              <v-icon class="my-0 mr-2 ml-2" dense>mdi-file</v-icon>
            </v-btn>
            <download-excel
              class="
                mb-2
                mx-5
                cursorPointer
                v-btn
                theme--dark
                v-size--small
                success
                v-btn v-btn--is-elevated v-btn--has-bg
              "
              :data="table.items"
              :fields="table.itemExport"
              type="xls"
              :header="$t(config.tableName)"
              :worksheet="$t(config.tableName)"
              :name="$t(config.tableName)"
            >
              <span class="excelLabel">{{ $t("ExportExcel") }}</span>
              <v-icon
                class="my-0 mr-2 ml-2"
                style="color: #fff !important"
                dense
                >mdi-export</v-icon
              >
            </download-excel>

            <v-btn
              color="danger"
              @click="loadPDF"
              dark
              small
              class="mb-2 mr-2 ml-2"
            >
              <span class="pdfLabel"> {{ $t("LoadPDF") }} </span>
              <v-icon class="my-0 mr-2 ml-2" dense>mdi-eye</v-icon>
            </v-btn>
          </div>
        </template>

        <div v-if="dialog">
          <v-card>
            <v-card-title>
              <span class="text-h5" v-if="formData.id > 0">
                {{ $t("Edit") }}
              </span>
              <span class="text-h5" v-else> {{ $t("Add") }} </span>
            </v-card-title>

            <v-card-text>
              <v-container fluid>
                <v-row>
                  <v-col class="py-0" :cols="4">
                    <div
                      :class="{
                        'has-error': errors.has(`addEditValidation.name_ar`),
                      }"
                    >
                      <label>{{ $t("NameAr") }}</label>
                      <v-text-field
                        class="d-block my-2"
                        type="text"
                        name="name_ar"
                        data-vv-scope="addEditValidation"
                        v-validate="'required'"
                        :data-vv-as="$t('NameAr')"
                        hide-details
                        dense
                        outlined
                        v-model="formData.name.ar"
                      ></v-text-field>
                    </div>

                    <div
                      class="help-block"
                      v-if="errors.has(`addEditValidation.name_ar`)"
                    >
                      {{ errors.first(`addEditValidation.name_ar`) }}
                    </div>
                  </v-col>

                  <v-col class="py-0" :cols="4">
                    <div
                      :class="{
                        'has-error': errors.has(`addEditValidation.name_en`),
                      }"
                    >
                      <label>{{ $t("NameEn") }}</label>
                      <v-text-field
                        class="d-block my-2"
                        type="text"
                        name="name_en"
                        data-vv-scope="addEditValidation"
                        v-validate="''"
                        :data-vv-as="$t('NameEn')"
                        hide-details
                        dense
                        outlined
                        v-model="formData.name.en"
                      ></v-text-field>
                    </div>

                    <div
                      class="help-block"
                      v-if="errors.has(`addEditValidation.name_en`)"
                    >
                      {{ errors.first(`addEditValidation.name_en`) }}
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-container fluid>
                <v-row class="float-left">
                  <v-btn
                    class="mr-2 ml-2"
                    color="danger"
                    dark
                    text
                    small
                    @click="closeModal"
                  >
                    {{ $t("Cancel") }}
                  </v-btn>
                  <v-btn
                    class="mr-2 ml-5"
                    color="success"
                    dark
                    outlined
                    small
                    @click="save"
                  >
                    {{ $t("Save") }}
                  </v-btn>
                </v-row>
              </v-container>
            </v-card-actions>
          </v-card>
        </div>
      </v-dialog>
    </v-toolbar>

    <!-- Start Filters -->
    <div>
      <v-row>
        <v-col :md="3">
          <div>
            <label>{{ $t("Name") }}</label>
            <v-text-field
              class="d-block my-2"
              type="text"
              name="name_ar"
              hide-details
              dense
              outlined
              v-model="filters.name"
            ></v-text-field>
          </div>
        </v-col>

        <v-col :md="3">
          <div>
            <label>{{ $t("Status") }}</label>
            <v-select
              :clearable="false"
              class="d-block my-2"
              name="status"
              hide-details
              dense
              outlined
              :multiple="false"
              item-text="text"
              item-value="value"
              v-model="filters.status"
              :items="filtersItems"
            ></v-select>
          </div>
        </v-col>

        <v-col :md="1" class="mt-6">
          <v-btn
            color="third"
            hide-details
            @click="getDataFromApi"
            style="display: block; margin-top: 10px"
            class="w-full h-btn white-clr"
            small
          >
            {{ $t("Filter") }}
          </v-btn>
        </v-col>
        <v-col :md="1" class="mt-6">
          <v-btn
            color="danger"
            hide-details
            @click="clearFilters"
            style="display: block; margin-top: 10px"
            class="w-full h-btn white-clr"
            small
          >
            {{ $t("Clear") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <!-- End Filters -->

    <!-- Start DataTable -->
    <v-data-table
      :headers="table.headers"
      :items="table.items"
      :loading="table.loading"
      :items-per-page="table.itemsPerPage"
      :page.sync="table.page"
      hide-default-footer
      class="elevation-1"
      :loading-text="$t('LoadData')"
    >
      <template v-slot:no-data>
        <v-alert class="mt-5 white-clr" color="third">
          {{ $t("NoData") }}
        </v-alert>
      </template>

      <template v-slot:item.status="{ item }">
        <p
          @click="updateStatus(item)"
          v-if="item.status"
          class="my-0 clr-success clickable"
        >
          {{ $t("Active") }}
        </p>
        <p @click="updateStatus(item)" v-else class="my-0 clr-danger clickable">
          {{ $t("InActive") }}
        </p>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="
            user &&
            user.permissions &&
            user.permissions.includes('update_division')
          "
          class="my-0 mr-2 ml-2"
          dense
          @click="
            formData = Object.assign({}, item);
            dialog = true;
          "
          color="success"
          >mdi-pencil</v-icon
        >

        <v-icon
          v-if="
            user &&
            user.permissions &&
            user.permissions.includes('delete_division')
          "
          class="my-0 mr-2 ml-2"
          dense
          @click="
            formData = Object.assign({}, item);
            dialogDelete = true;
          "
          color="danger"
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
    <!-- End DataTable -->

    <!-- Start Pagination -->
    <div class="text-center pt-2">
      <v-pagination
        v-if="table.length"
        total-visible="5"
        v-model="table.page"
        :length="table.length"
        @input="changePage(table.page)"
      ></v-pagination>
    </div>

    <!-- End Pagination -->
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "divisions",
  data() {
    return {
      user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : [],
      filtersItems: [
        { text: this.$t("All"), value: null },
        { text: this.$t("Active"), value: 1 },
        { text: this.$t("InActive"), value: 0 },
      ],
      config: {
        tableName: "DivisionsTable",
        crudHeader: "Divisions",
        crudApi: "division",
        filterWidth: "20rem",
        deleteModalWidth: "30rem",
        modalFormWidth: "50rem",
      },
      table: {
        page: 1,
        length: null,
        itemsPerPage: 10,
        loading: false,
        items: [],
        itemExport: {
          "#": "id",
          "الاسم عربي": {
            field: "name.ar",
            callback: (item) => {
              if (item) return item;
            },
          },
          "الاسم إنجليزي": {
            field: "name.en",
            callback: (item) => {
              if (item) return item;
            },
          },
          الحالة: {
            field: "status",
            callback: (value) => {
              if (value) return "فعال";
              else return "غير فعال";
            },
          },
        },
        headers: [
          { text: "#", value: "id", width: "5rem" },
          {
            text: this.$t("NameAr"),
            value: "name.ar",
            width: "10rem",
            // class: "nameAction",
            // cellClass: "nameActionCell",
          },
          {
            text: this.$t("NameEn"),
            value: "name.en",
            width: "10rem",
          },
          {
            text: this.$t("Status"),
            value: "status",
            width: "8rem",
          },
        ],
      },
      filters: {
        name: null,
        status: null,
      },
      formData: {
        id: 0,
        image: null,
        name: {
          ar: null,
          en: null,
        },
        status: null,
      },
      drawer: false,
      dialog: false,
      dialogDelete: false,
    };
  },
  methods: {
    loadPDF() {
      localStorage.setItem("filterReport", JSON.stringify(this.filters));
      window.open(`/reports/${this.config.crudApi}`);
    },
    clearFilters() {
      for (let key in this.filters) {
        this.filters[key] = null;
      }
      this.getDataFromApi();
    },
    clearData() {
      this.formData = {
        name: {
          ar: null,
          en: null,
        },
        image: null,
      };
      this.$validator.reset();
    },
    deleteItemConfirm() {
      this.deleteData(
        `${this.config.crudApi}/removeData`,
        this.formData.id
      ).then(() => {
        this.getDataFromApi();
        this.dialogDelete = false;
      });
    },
    updateStatus(item) {
      item.isLoading = true;
      this.updateStatusData(`${this.config.crudApi}/updateStatus`, item.id)
        .then(() => {
          this.getDataFromApi();
        })
        .finally(() => {
          item.isLoading = false;
        });
    },
    closeModal() {
      this.formData.id = 0;
      this.dialog = false;
      this.$validator.reset();
    },
    save() {
      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");
        this.saveUpdateData(
          `${this.config.crudApi}/saveData`,
          `${this.config.crudApi}/updateData`,
          this.formData
        ).then(() => {
          this.getDataFromApi();
          this.closeModal();
        });
      });
    },
    getDataFromApi(options) {
      this.table.loading = true;
      this.getFrontEndData(
        `${this.config.crudApi}/getData`,
        options,
        this.filters
      )
        .then((res) => {
          this.table.items = res.resources;
          this.table.length = Math.ceil(
            res.pagination.total / res.pagination.per_page
          );
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    changePage(page) {
      let options = {
        page: page,
      };
      this.getDataFromApi(options);
    },
  },
  mounted() {
    this.getDataFromApi();

    if (
      (this.user &&
        this.user.permissions &&
        this.user.permissions.includes("update_division")) ||
      (this.user &&
        this.user.permissions &&
        this.user.permissions.includes("delete_division"))
    )
      this.table.headers.push({
        text: this.$t("Actions"),
        value: "actions",
        sortable: false,
        align: "center",
        width: "8rem",
      });
  },
};
</script>